import { React, Helper } from "../../common";
import Container from "../container";
import { GetUserSubs, SetUserSubs } from "../../services/subscriptionApi";

const Component = () => {

    const [initlized, setInitlized] = React.useState(false);
    const [rows, setRows] = React.useState({});
    const [error, setError] = React.useState(null);

    const FetchResults = async () => {
        global.Busy(true);
        let rslt = await GetUserSubs();
        if (rslt.status === 100) {
            setRows(rslt.data);
        }
        global.Busy(false);
    };

    const OnSubClicked = (e) => {
        let _childs = rows.subscriptions;
        _childs.find((x) => x.subid === e.target.value).statusid = e.target.checked ? 1 : 0;
        setRows({ ...rows, subscriptions: _childs });
    }

    const OnDummyChanged = async () => { };

    const OnSubmitClicked = async (e) => {
        e.preventDefault();
        global.Busy(true);
        let rslt = await SetUserSubs(rows);
        global.Busy(false);
        if (rslt.status !== 100) {
            setError(rslt.statusText);
            return;
        }
        await FetchResults();
    }

    if (initlized) {
        setInitlized(false);
        setError(null);
        setRows({});
        FetchResults();
    }

    React.useEffect(() => {
        setInitlized(true);
    }, []);

    return (
        <>
            <Container>
                <div id="container" >
                    <div id="content">
                        <div className="widthfix_10px">
                            <div className="subscription_pgBlk">
                                <div className="subscription_PgHead">Subscription Preferences</div>
                                <div className="subscription_PgDescHead">Uncheck the type of emails you do not want to receive</div>
                                <ul className="subscriptionIIcol">
                                    {rows && rows.subscriptions && rows.subscriptions.length > 0 &&
                                        rows.subscriptions.map((x) => (
                                            <li className="subscriptionIIcol" key={x.subid}>
                                                <div className="subscriptionCheckboxLabel">
                                                    <input type="checkbox" id={`chkSub_${x.subid}`}
                                                        value={x.subid}
                                                        checked={parseInt(x.statusid) === 1}
                                                        onClick={(e) => OnSubClicked(e)}
                                                        onChange={() => OnDummyChanged()}
                                                    />
                                                    <label htmlFor={`chkSub_${x.subid}`}>{x.label}</label>
                                                </div>
                                            </li>
                                        ))
                                    }
                                </ul>
                                <div className="subscriptionBtnLabel">
                                    <input type="button" value="Update Preferences" className="subscriptionBtn"
                                        onClick={(e) => OnSubmitClicked(e)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    );
}

export default Component;