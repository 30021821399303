import { React, Link, Helper, Session, useNavigate } from "../../common";
import Container from "../container";
import { GetLoans } from "../../services/loanApi";

const Component = () => {
  const [initlized, setInitlized] = React.useState(false);
  const [rows, setRows] = React.useState([]);
  const navigate = useNavigate();

  React.useEffect(() => {
    setInitlized(true);
  }, []);

  const FetchResults = async () => {
    global.Busy(true);
    setRows([]);
    let rslt = await GetLoans();
    console.log(rslt);
    if (rslt.status === 100) {
      setRows(rslt.data);
    }
    global.Busy(false);
  };

  if (initlized) {
    setInitlized(false);
    FetchResults();
  }

  const OnSelectedLoan = async (e, x) => {
    e.preventDefault();
    Session.Store("coBorrowerIndicator", x.coBorrowerIndicator);
    Session.Store("loanid", x._id);
    let stage = x.stageIndicator === 0 ? 1 : x.stageIndicator;
    navigate(`/stage${stage}`);
  };

  const RenderStatusAction = ({ x }) => {
    if (parseInt(x.statusIndicator) === 0) {
      return (
        <>
          <td>Saved</td>
          <td>
            <Link
              to="#"
              onClick={(e) => OnSelectedLoan(e, x)}
              title="Resume Application"
            >
              <div className="form1003__resumeForm"></div>
            </Link>
          </td>
        </>
      );
    } else if (parseInt(x.statusIndicator) === 1) {
      return (
        <>
          <td>Submitted</td>
          <td>&nbsp;</td>
        </>
      );
    } else if (parseInt(x.statusIndicator) === 3) {
      return (
        <>
          <td>Completed</td>
          <td>&nbsp;</td>
        </>
      );
    }
    return null;
  };

  const RenderLoanNumber = ({ x }) => {
    if (Helper.IsNullValue(x.loanNumber))
      return (
        <td style={{ textAlign: "center" }}><pre>&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;</pre></td>
      );
    return <td>{x.loanNumber}</td>;
  };

  return (
    <>
      <Container>
        <div id="container">
          <div id="content">
            <div className="widthfix_10px">
              <div className="form1003_pgBlk">
                <ul className="formHeadStageBlk">
                  <li className="formHeadStageBlk">1003 Form - Saved &amp; Completed Applications</li>
                  <li className="formHeadStageBlk">&nbsp;</li>
                </ul>
                {rows && rows.length > 0 ? (
                  <div className="form1003_savedCompleted_blk">
                    <div className="savedCompleted_head">
                      <table>
                        <tbody>
                          <tr>
                            <td>Ref No.</td>
                            <td>App No.</td>
                            <td>Borrower</td>
                            <td>Phone</td>
                            <td>Email</td>
                            <td>Address</td>
                            <td>Status</td>
                            <td>&nbsp;</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="savedCompleted">
                      <table>
                        <tbody>
                          {rows &&
                            rows.map((x) => {
                              return (
                                <tr key={x._id}>
                                  <td>{x.loanRefNumber}</td>
                                  <RenderLoanNumber x={x} />
                                  <td>
                                    {Helper.GetStringJoin(x, [
                                      "firstName",
                                      "middleName",
                                      "lastName",
                                    ])}
                                  </td>
                                  <td>{Helper.ToString(x.mobilePhone)}</td>
                                  <td>{x.email}</td>
                                  <td>
                                    {Helper.GetStringJoin(x, [
                                      "addressLineText",
                                      "city",
                                      "state",
                                      "postalCode",
                                    ]) || "N/A"}
                                  </td>
                                  <RenderStatusAction x={x} />
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                ) : (
                  <div className="noContent">No application data available</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Component;
