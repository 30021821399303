import { React, Helper, useParams } from "../../common";
import Container from "../container";

import { GetUserRequestList, GetUserRequest, SetUserRequestStatus } from "../../services/consentApi";

const RenderConsentRequest = (props) => {

    const { item, onSubmitClicked } = props;
    const [consentStatus, setConsentStatus] = React.useState("");
    const [consentChecked, setConsentChecked] = React.useState(0);

    React.useEffect(() => {
        let tmpStatus = "";
        setConsentChecked(Helper.ToInteger(item.statusid));
        if (Helper.ToInteger(item.statusid) === 1) {
            if (Helper.ToBool(item.selfApproved)) {
                tmpStatus = `Consent approved on ${Helper.ToDateFormat(item.updatedAt, 'YYYY-MMM-DD')}`;
            } else {
                tmpStatus = `Consent provided on ${Helper.ToDateFormat(item.updatedAt, 'YYYY-MMM-DD')}`;
            }
        } else if (Helper.ToInteger(item.statusid) === 0) {
            tmpStatus = `Consent denined on ${Helper.ToDateFormat(item.createdAt, 'YYYY-MMM-DD')}`;
        }
        else if (Helper.ToInteger(item.statusid) === -1) {
            tmpStatus = `Consent pending for approval requested on ${Helper.ToDateFormat(item.createdAt, 'YYYY-MMM-DD')}`;
        }
        setConsentStatus(tmpStatus);
    }, [item]);

    const OnCheckboxClicked = (e) => {
        setConsentChecked(e.target.value);
    };

    const OnDummyChanged = () => { };

    const OnSubmitClicked = (e) => {
        e.preventDefault();
        if (onSubmitClicked) onSubmitClicked(item._id, consentChecked);
    };


    return (
        <li className="IIIcol">
            <div className="appcontentarea_table_border">
                <div className="app_headBlk_pad20">
                    <div className="app_headBlk_blkName">
                        {item.title}
                    </div>
                </div>
                <div className="appContentarea_pad15_bgTexure">
                    <div className="consentLO_TxtHead">{item.question}</div>
                    <ul className="appformColumn">
                        <li className="appformColumn_inline">
                            <div className="consent_radioLabel">
                                <input type="radio" id={`radioconsent_${item._id}_Y`}
                                    value={1}
                                    checked={parseInt(consentChecked) === 1 ? true : false}
                                    onChange={(e) => OnDummyChanged(e)}
                                    onClick={(e) => OnCheckboxClicked(e)}
                                    name={`radioconsent_${item._id}`} /><label htmlFor={`radioconsent_${item._id}_Y`}>Yes</label>
                            </div>
                        </li>
                        <li className="appformColumn_inline">
                            <div className="consent_radioLabel">
                                <input type="radio" id={`radioconsent_${item._id}_N`}
                                    value={0}
                                    checked={parseInt(consentChecked) === 0 ? true : false}
                                    onChange={(e) => OnDummyChanged(e)}
                                    onClick={(e) => OnCheckboxClicked(e)}
                                    name={`radioconsent_${item._id}`} /><label htmlFor={`radioconsent_${item._id}_N`}>No</label>
                            </div>
                        </li>
                    </ul>

                    <div className="consentButtonLabel">
                        <input type="button" value="Submit" className="consentButton" onClick={(e) => OnSubmitClicked(e)} />
                    </div>
                    <div className="consentLO_Txt">{consentStatus}</div>
                </div>
            </div>
        </li>
    );
}

const Component = () => {

    const [initlized, setInitlized] = React.useState(false);
    const [requestsList, setRequestsList] = React.useState([]);
    const [rows, setRows] = React.useState([]);
    const [error, setError] = React.useState(null);
    const [selectedReqId, setSelectedReqId] = React.useState("");
    const [requestSelected, setRequestSelected] = React.useState(false);
    const [loadResults, setLoadResults] = React.useState(false);
    const { id } = useParams();

    const FetchResults = async () => {
        global.Busy(true);
        setRequestsList([]);
        let rslt = await GetUserRequestList();
        if (rslt.status === 100) {
            console.log(rslt.data);
            let data = rslt.data || [];
            data = Helper.RemoveDuplicates(data, 'mapid');
            setRequestsList(data);
        }
        global.Busy(false);
    };

    const OnSubmitClicked = async (id, status) => {
        global.Busy(true);
        let rslt = await SetUserRequestStatus(id, status);
        if (rslt.status === 100) setLoadResults(!loadResults);
        global.Busy(false);
    }

    React.useEffect(() => {
        setRequestSelected(false);
        setRows([]);
        if (!Helper.IsNullValue(selectedReqId)) {
            setRequestSelected(true);
            const fn = async () => {
                global.Busy(true);
                setRows([]);
                let rslt = await GetUserRequest(selectedReqId);
                if (rslt.status === 100) {
                    let data = rslt.data || [];
                    console.log(data);
                    setRows(data);
                }
                global.Busy(false);
            };

            fn();
        }
    }, [selectedReqId, loadResults]);

    if (initlized) {
        setInitlized(false);
        setError(null);
        setRows([]);
        FetchResults();
    }

    React.useEffect(() => {
        setInitlized(true);
    }, []);

    React.useEffect(() => {
        setSelectedReqId(id);
    }, [id]);

    return (
        <>
            <Container>
                <div id="container" >
                    <div id="content">
                        <div className="widthfix_10px">
                            <div className="consentLO_pgBlk">
                                <div className="consentLO_PgHead_Blk">
                                    <div className="consentLO_PgHead">Consents</div>
                                    <div className="consentSelectLoan">
                                        <select
                                            name="filterType"
                                            value={Helper.ToString(selectedReqId)}
                                            onChange={(e) => setSelectedReqId(e.target.value)}
                                        >
                                            <option value="">Select Loan No</option>
                                            {requestsList && requestsList.map((x) => (<option value={x.mapid} key={x._id}>{x.refNumber}</option>))}
                                        </select>

                                    </div>
                                </div>

                                {!requestSelected && <div className="noContent">Select Loan Number to view consents</div>}
                                {requestSelected && <ul className="IIIcol">
                                    {rows && rows.map((x) => (
                                        <RenderConsentRequest onSubmitClicked={OnSubmitClicked} item={x} key={x._id} />
                                    ))}
                                </ul>}
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default Component;
