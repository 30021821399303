import { React, Helper, UploadHelper, BreadCrumb, useParams } from "../../../common";
import Container from "../../container";

import {
  GetPreDocRequests, GetPreDocTypes,
  AddPreDocument, GetPreDocuments, DeletePreDocument
} from "../../../services/preapprovalApi";

const maxsize = 106000000;
const formats = ["PNG", "JPG", "PDF"];

const Component = () => {
  const [initlized, setInitlized] = React.useState(false);
  const [docInitlized, setDocInitlized] = React.useState(false);
  const [requestsList, setRequestsList] = React.useState([]);
  const [selectedReqId, setSelectedReqId] = React.useState("");
  const [requestHeader, setRequestHeader] = React.useState("");
  const [requestSelected, setRequestSelected] = React.useState(false);
  const [docTypes, setDocTypes] = React.useState([]);
  const [docList, setDocList] = React.useState([]);
  const [docTypeId, setDocTypeId] = React.useState("");

  const [row, setRow] = React.useState({});
  const [error, setError] = React.useState(null);
  const [fileurl, setFileUrl] = React.useState(null);
  const [formatError, setFormatError] = React.useState(null);

  const { id } = useParams();

  const OnInputChanged = (name, val) => {
    setError(null);
    setRow((prev) => ({
      ...prev,
      [name]: val,
    }));
  };

  const OnFileInputChanged = (e) => {
    setError(null);
    let _file = e.target.files[0];
    setFormatError(null);
    setFileUrl(null);
    if (!Helper.IsNullValue(_file)) {
      let _ext = _file.name.split(".").pop();
      let _index = formats.findIndex((x) => x === _ext.toUpperCase());
      if (_index > -1) {
        if (_file.size > maxsize) {
          Helper.NativeInputClick("loandocs");
          setFormatError("File size allowed: 100mb");
        } else {
          setFileUrl(e.target.files[0]);
        }
      } else {
        Helper.NativeInputClick("loandocs");
        setFormatError("Supported Format: " + formats.join(","));
      }
    }
  };

  const OnFileUploadClicked = async (e) => {
    e.preventDefault();
    setError(null);

    if (Helper.IsJSONEmpty(row) || Helper.IsNullValue(fileurl)) {
      setError("All fields are mandatory");
      return;
    } else if (Helper.IsNullValue(row?.docname) || Helper.IsNullValue(row?.docinfo)) {
      setError("All fields are mandatory");
      return;
    }

    let _ext = fileurl.name.split(".").pop();
    let _tmp = docTypes.filter((x) => x._id === docTypeId)[0];
    let guid = Helper.GetGUID();
    let obj = {
      ...row,
      doctype: _tmp.name,
      docTypeId: docTypeId,
      mapid: selectedReqId,
      loannumber: requestHeader,
      ofileName: fileurl.name,
      req: {
        fileName: fileurl.name,
        predocs: true,
        blobName: `${selectedReqId}/${docTypeId}/${_tmp.name}/${guid}.${_ext}`
      }
    };

    let rslt = await UploadHelper.Upload(fileurl, obj);
    if (rslt.status !== 100) {
      Helper.NativeInputClick("loandocs");
      setError(rslt.statusText);
      return;
    }

    rslt = await AddPreDocument(obj);
    if (rslt.status !== 100) {
      Helper.NativeInputClick("loandocs");
      setError(rslt.statusText);
      return;
    }

    setRow({});
    setSelectedReqId("");
    setDocTypeId("");
    Helper.NativeInputClick("loandocs");
  };

  const OnDeleteFileClicked = async (e, id) => {
    e.preventDefault();
    setError(null);
    global.Busy(true);
    let rslt = await DeletePreDocument(id);
    global.Busy(false);
    if (rslt.status !== 100) {
      setError(rslt.statusText);
      return;
    }
    await LoadDocuments();
  };

  React.useEffect(() => {
    setFormatError(null);
    setError(null);
  }, [docTypeId]);

  const LoadDocuments = async () => {
    setDocList([]);
    let data = [];
    let rslt = await GetPreDocuments(selectedReqId);
    if (rslt.status === 100) data = rslt.data?.list || [];
    setDocList(data);
  }

  React.useEffect(() => {
    setRequestSelected(false);
    setRequestHeader("");
    setFormatError(null);
    setError(null);
    setDocTypeId("");
    if (!Helper.IsNullValue(selectedReqId)) setDocInitlized(true);
  }, [selectedReqId, requestsList]);

  React.useEffect(() => {
    setSelectedReqId(id);
  }, [id]);

  const FetchResults = async () => {
    global.Busy(true);
    setRequestsList([]);
    let _request1 = [];
    let rslt = await GetPreDocRequests();
    if (rslt.status === 100) _request1 = rslt.data || [];
    setRequestsList(_request1);
    global.Busy(false);
  };

  const InitDocument = async () => {
    global.Busy(true);
    let _tmp = requestsList.filter((x) => x.mapid === selectedReqId)[0];
    setRequestHeader(_tmp?.refNumber);
    let data = [];
    let rslt = await GetPreDocTypes(selectedReqId);
    if (rslt.status === 100) data = rslt.data.childs || [];
    _tmp = data.filter((x) => x.checked);
    setDocTypes(_tmp);
    await LoadDocuments();
    setRequestSelected(true);
    global.Busy(false);
  }

  if (docInitlized) {
    setDocInitlized(false);
    InitDocument();
  }

  if (initlized) {
    setInitlized(false);
    setRequestSelected(false);
    setRequestHeader("");
    setDocTypeId("");
    FetchResults();
  }

  React.useEffect(() => {
    setInitlized(true);
  }, []);

  return (
    <>
      <Container>
        <div id="container">
          <div id="content">
            <div className="widthfix_10px">
              <BreadCrumb home="Documents" title="Pre-Approval Documents Upload" />
              <div className="document_pgBlk">
                <div className="appPgStructure_Icol">
                  <div className="app_box_shadow">
                    <ul className="summaryVblk">
                      <li className="summaryVblk">
                        <div className="summaryHead">Reference Number:</div>
                        <div className="loanSearchSelect">
                          <select
                            name="filterType"
                            value={Helper.ToString(selectedReqId)}
                            onChange={(e) => setSelectedReqId(e.target.value)}
                          >
                            <option value="">Select</option>
                            {requestsList && requestsList.map((x) => (<option value={x.mapid} key={x._id}>{x.refNumber}</option>))}
                          </select>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>


                {requestSelected && (
                  <div className="appPgStructure_Icol">
                    <div className="app_box_shadow_noPad">
                      <div className="app_headBlk_pad20">
                        <div className="app_headBlk_blkName">Upload Documents for Loan No: {requestHeader}</div>
                      </div>
                      <div className="appContentarea_pad20">
                        <ul className="appformColumn">
                          <div className="uploadDoc">
                            <div className="uploadDocHeadBlk">
                              <div className="uploadHeading">Document Type</div>
                              <div className="selectDocType">
                                <select
                                  name="filterStatus"
                                  required
                                  value={Helper.ToString(docTypeId)}
                                  onChange={(e) => setDocTypeId(e.target.value)}
                                >
                                  <option value="">Select</option>
                                  {docTypes && docTypes.map((x) => (<option value={x._id} key={x._id}>{x.name}</option>))}
                                </select>
                              </div>
                            </div>
                            <div className="appContentarea_pad20_bgTexure">
                              {!Helper.IsNullValue(docTypeId) && (
                                <ul className="uploadIIIcol">
                                  <li className="uploadIIIcol">
                                    <div className="appformContentHead">Document Name</div>
                                    <div className="documentFormicon"></div>
                                    <input
                                      type="text"
                                      value={Helper.ToString(row.docname)}
                                      onChange={(e) =>
                                        OnInputChanged(
                                          "docname",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </li>
                                  <li className="uploadIIIcol">
                                    <div className="appformContentHead">Document Information</div>
                                    <div className="documentFormicon"></div>
                                    <input
                                      type="text"
                                      value={Helper.ToString(row.docinfo)}
                                      onChange={(e) =>
                                        OnInputChanged(
                                          "docinfo",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </li>
                                  <li className="uploadIIIcol">
                                    <div className="appformContentHead">Document Upload</div>
                                    <div className="fileUpload">
                                      <div className="uploadBox">
                                        <input
                                          id="loandocs"
                                          type="file"
                                          name="loandocs"
                                          onClick={(e) => {
                                            e.target.value = null;
                                          }}
                                          onChange={(e) =>
                                            OnFileInputChanged(e)
                                          }
                                        />
                                      </div>
                                      <div className="buttonUploadLayout">
                                        <input
                                          type="submit"
                                          className="buttonUpload"
                                          value="Upload"
                                          onClick={(e) =>
                                            OnFileUploadClicked(e)
                                          }
                                        />
                                      </div>
                                      {formatError && (<div className="submitError"> {formatError} </div>)}
                                    </div>
                                  </li>
                                </ul>
                              )}
                              <div className="uploadSubHead">
                                Uploaded Documents
                              </div>
                              <div className="appcontentarea_table_border">
                                <div className="viewDocTable_head">
                                  <table>
                                    <tbody>
                                      <tr>
                                        <td>Document Name</td>
                                        <td>Document Type</td>
                                        <td>Document Information</td>
                                        <td>&nbsp;</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                                <div className="viewDocTable">
                                  <table>
                                    <tbody>
                                      {docList && docList.length > 0 && docList.map((x) => (
                                        <tr key={x._id}>
                                          <td>{x.docname}</td>
                                          <td>{x.doctype}</td>
                                          <td>{x.docinfo}</td>
                                          <td>
                                            <input
                                              type="button"
                                              value="Delete"
                                              className="viewDoc_btn"
                                              onClick={(e) => OnDeleteFileClicked(e, x._id)}
                                            />
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                            {error && (<div className="errormessage">{error}</div>)}
                          </div>
                        </ul>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Component;
