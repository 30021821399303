import {
  React, Link, useNavigate, UseTimerStore,
  useTimerSession, useSearchParams, useLocation,
  Session, Storage
} from "../../common";

import {
  Authentication, ZipPopup, Authorization,
  ConfirmDialog, ChangePassword, ProfileComplete
} from "../popups";

// Buy a Home
const mnu1 = ["/firsthome", "/nexthome", "/newhome", "/residential", "/foreclosed"];

// Refinance
const mnu2 = ["/lowerpayment", "/reducerate", "/payoff", "/convert", "/consolidate", "/prepare", "/steps"];

// Mortgage Tools
const mnu3 = ["/loanprocess", "/mortgagerates", "/mortgagecalculator", "/mortgagecalculator",
  "/refinancecalculator", "/principalcalculator", "/extrapayment", "/incometoqualify", "/affordability",
  "/taxbenefit", "/apr", "/faq"];

// Loan Options
const mnu4 = [
  "/thirty",
  "/fha",
  "/va",
  "/reverse",
  "/usda",
  "/jumbo",
  "/renovation",
  "/nonqm",
  "/conventional",
];
// More
const mnu5 = ["/stats", "/about", "/career", "/blogs", "/reviews", "/contact"];
// Dashboard
const mnu6 = ["/dashboard"];
// Documents
const mnu7 = ["/search", "/docupload", "/docview", "/docapprove", "/predocs"];
// PreApproved
const mnu8 = ["/preapprove", "/letter"];
// Form 1003
const mnu9 = [
  "/form1003",
  "/short",
  "/saved",
  "/download",
  "/stage1",
  "/stage2",
  "/stage3",
  "/stage4",
  "/stage5",
  "/stage6",
  "/short",
  "/saved",
  "/thank",
];

const IsActive = (mnu, icon) => {
  const loc = useLocation().pathname;
  if (icon) {
    return mnu.findIndex((x) => x === loc) > -1 ? `${icon} active` : icon;
  }
  return mnu.findIndex((x) => x === loc) > -1 ? "dropbtn active" : "dropbtn";
};

const LoggedOutHeader = () => {
  const [searchParams] = useSearchParams();
  let params = searchParams.get("q") || undefined;

  React.useEffect(() => {
    if (params) {
      window.fnSign(1);
    }
  }, [params]);

  return (
    <>
      <header className="header">
        <div className="widthfix">
          <div className="logo">
            <Link to="/">
              <div className="i3LendingLogo logoPositionHome"></div>
            </Link>
          </div>
          <div className="topRight_fixed_blk">
            <div className="widthfix">
              <div className="headerTopRight">
                <ul className="header_Account_blk">
                  <li className="header_profile_img"></li>
                  <li className="header_nameAcc_blk">
                    <div className="headerProfileName">Hello, Sign In</div>
                    <div className="headerAccount">
                      <div className="accountnav" id="myAccountnav">
                        <div className="dropdownAcc">
                          <button className="dropbtnAcc">
                            Account <div className="arrow_down"></div>
                          </button>
                          <div className="dropdown-content">
                            <Link
                              to="#"
                              className="icon"
                              onClick={() => window.fnSign(1)}
                            >
                              <div className="loginIcon"></div>Login / Signup
                            </Link>
                            <Link to="/support">
                              <div className="supportIcon"></div>Help &amp;
                              Support
                            </Link>
                            {/* <Link to="/WIP">
                              <div className="fgtpasswordIcon"></div>Forgot
                              Password
                            </Link> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
                <div className="header_Ph_blk">
                  <div className="callus">Call us at</div>
                  <div className="phNo">(888) 975-1374</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="navBlock">
          <div className="widthfix">
            <div className="headerRightBlock">
              <ul className="supportNavi">
                <li className="supportNavi">
                  <Link to="/becomebranch" title="Become a Branch">
                    <div className="becomeBranchIcon"></div>
                    <div className="supportNaviText">Become a Branch</div>
                  </Link>
                </li>
                <li className="supportNavi">
                  <Link to="/findlo" title="Find an Agent">
                    <div className="findOfficerIcon"></div>
                    <div className="supportNaviText">Find a Loan Officer</div>
                  </Link>
                </li>
              </ul>
            </div>

            <div className="topnav">
              <div className="dropdown">
                <button className={IsActive(mnu1)}>
                  Buy a Home <div className="arrow_down_topnav"></div>
                </button>
                <div className="dropdown-content">
                  <Link to="/firsthome">Buy your First Home</Link>
                  <Link to="/nexthome">Buy your Next Home</Link>
                  <Link to="/newhome">Build New Home</Link>
                  <Link to="/residential">
                    Investment on Residential Property
                  </Link>
                  <Link to="/foreclosed">Buy a Foreclosed Home</Link>
                </div>
              </div>
              <div className="dropdown">
                <button className={IsActive(mnu2)}>
                  Refinance <div className="arrow_down_topnav"></div>
                </button>
                <div className="dropdown-content">
                  <Link to="/lowerpayment">Lower Your Monthly Payment</Link>
                  <Link to="/reducerate">Reduce Your Current Rate</Link>
                  <Link to="/payoff">Pay Off Your Mortgage</Link>
                  <Link to="/convert">Convert To Fixed Rate</Link>
                  <Link to="/consolidate">Consolidate Your Debt</Link>
                  <Link to="/prepare">Prepare For Refinancing</Link>
                  <Link to="/steps">Steps to Refinance</Link>
                </div>
              </div>

              <div className="dropdown">
                <button className={IsActive(mnu3)}>
                  Mortgage Tools <div className="arrow_down_topnav"></div>
                </button>
                <div className="dropdown-content">
                  <Link to="/loanprocess">Loan Process</Link>
                  <Link to="/mortgagerates">Mortgage Rates</Link>
                  <Link to="/mortgagecalculator">Mortgage Calculator</Link>
                  {/*  <Link to="/refinancecalculator">Refinance Calculator</Link>
                  <Link to="/affordability">Home Affordability</Link> */}
                  <Link to="/faq">FAQ's</Link>
                </div>
              </div>

              <div className="dropdown">
                <button className={IsActive(mnu4)}>
                  Loan Options <div className="arrow_down_topnav"></div>
                </button>
                <div className="dropdown-content">
                  <Link to="/thirty">30 Year Fixed Rate</Link>
                  <Link to="/fha">FHA Loans</Link>
                  <Link to="/va">VA Loans</Link>
                  <Link to="/reverse">Reverse Mortgages</Link>
                  <Link to="/usda">USDA Loans</Link>
                  <Link to="/conventional">Conventional Financing</Link>
                  <Link to="/jumbo">Jumbo Loans</Link>
                  <Link to="/renovation">Renovation Loans</Link>
                  <Link to="/nonqm">NON QM</Link>
                </div>
              </div>
              <div className="dropdown">
                <button className={IsActive(mnu5)}>
                  More <div className="line_topnav"></div>
                </button>
                <div className="dropdown-content">
                  <Link to="/stats">Lending Statistics</Link>
                  <Link to="/about">About Us</Link>
                  <Link to="/career">Careers</Link>
                  <Link to="/blogs">Blogs</Link>
                  <Link to="/reviews">Reviews</Link>
                  <Link to="/contact">Contact Us</Link>
                </div>
              </div>
              {/* <Link to="#" className="icon" onClick={() => window.myFunction()}></Link> */}
            </div>
          </div>
        </div>
      </header>

      <header className="headerResponsive">
        <div className="headerTopRightResponsive">
          <ul className="header_Account_blk_responsive">
            <li className="header_profile_img_responsive"></li>
            <li className="header_nameAcc_blk_responsive">
              <div className="headerProfileName_mini">Hello, Sign In</div>
              <div className="headerAccount_mini">
                <div className="accountnav_mini" id="myAccountnav">
                  <div className="dropdownAcc_mini">
                    <button className="dropbtnAcc_mini">
                      Account <div className="arrow_down_mini"></div>
                    </button>
                    <div className="dropdown-content_mini">
                      <Link
                        to="#"
                        className="icon"
                        onClick={() => window.fnSign(1)}
                      >
                        <div className="loginIcon"></div>Login / Signup
                      </Link>
                      <Link to="/support">
                        <div className="supportIcon"></div>Help &amp; Support
                      </Link>
                      {/* <Link to="#">
                        <div className="fgtpasswordIcon"></div>Forgot Password
                      </Link> */}
                    </div>
                  </div>
                  <Link to="#" className="icon"></Link>
                </div>
              </div>
            </li>
          </ul>
        </div>

        <div className="navBlockResponsive">
          <div className="topnav" id="myTopnav">
            <Link to="/">
              <div className="logoPosResponsive i3LendingLogo"></div>
            </Link>
            <div className="dropdown">
              <button className={IsActive(mnu1)}>
                Buy a Home <div className="arrow_down_topnav"></div>
              </button>
              <div className="dropdown-content">
                <Link to="/firsthome">Buy your First Home</Link>
                <Link to="/nexthome">Buy your Next Home</Link>
                <Link to="/newhome">Build New Home</Link>
                <Link to="/residential">
                  Investment on Residential Property
                </Link>
                <Link to="/foreclosed">Buy a Foreclosed Home</Link>
              </div>
            </div>
            <div className="dropdown">
              <button className={IsActive(mnu2)}>
                Refinance <div className="arrow_down_topnav"></div>
              </button>
              <div className="dropdown-content">
                <Link to="/lowerpayment">Lower Your Monthly Payment</Link>
                <Link to="/reducerate">Reduce Your Current Rate</Link>
                <Link to="/payoff">Pay Off Your Mortgage</Link>
                <Link to="/convert">Convert To Fixed Rate</Link>
                <Link to="/consolidate">Consolidate Your Debt</Link>
                <Link to="/prepare">Prepare For Refinancing</Link>
                <Link to="/steps">Steps to Refinance</Link>
              </div>
            </div>
            <div className="dropdown">
              <button className={IsActive(mnu3)}>
                Mortgage Tools <div className="arrow_down_topnav"></div>
              </button>
              <div className="dropdown-content">
                <Link to="/loanprocess">Loan Process</Link>
                <Link to="/mortgagerates">Mortgage Rates</Link>
                <Link to="/mortgagecalculator">Mortgage Calculator</Link>
                {/*  <Link to="/refinancecalculator">Refinance Calculator</Link>
                  <Link to="/affordability">Home Affordability</Link> */}
                <Link to="/faq">FAQ's</Link>
              </div>
            </div>
            <div className="dropdown">
              <button className={IsActive(mnu4)}>
                Loan Options <div className="arrow_down_topnav"></div>
              </button>
              <div className="dropdown-content">
                <Link to="/thirty">30 Year Fixed Rate</Link>
                <Link to="/fha">FHA Loans</Link>
                <Link to="/va">VA Loans</Link>
                <Link to="/reverse">Reverse Mortgages</Link>
                <Link to="/usda">USDA Loans</Link>
                <Link to="/conventional">Conventional Financing</Link>
                <Link to="/jumbo">Jumbo Loans</Link>
                <Link to="/renovation">Renovation Loans</Link>
                <Link to="/nonqm">NON QM</Link>
              </div>
            </div>
            <div className="dropdown">
              <button className={IsActive(mnu5)}>
                More <div className="line_topnav"></div>
              </button>
              <div className="dropdown-content">
                <Link to="/stats">Lending Statistics</Link>
                <Link to="/about">About Us</Link>
                <Link to="/career">Careers</Link>
                <Link to="/blogs">Blogs</Link>
                <Link to="/reviews">Reviews</Link>
                <Link to="/contact">Contact Us</Link>
              </div>
            </div>
            <Link
              to="#"
              className="icon"
              onClick={() => window.myFunction()}
            ></Link>
          </div>
        </div>
      </header>
    </>
  );
};

const LoggedInHeaderMenuItems = (props) => {
  const { stage, responsive, onGettingStarted, onResumeStarted } = props;
  const divId = responsive ? "myTopnav" : "myTopnavNormal";
  const NavigateTo = useNavigate();
  const [userStatus] = useTimerSession('status');

  const OnAuthorizeApp = (e) => {
    e.preventDefault();
    if (parseInt(userStatus) === 1) {
      document.getElementById("popAuthorize").style.display = "block";
    } else {
      ShowProfileCompleteConfirmation();
    }
  };

  const OnNavigateClicked = (e, link) => {
    e.preventDefault();
    if (parseInt(userStatus) === 1) {
      NavigateTo(link);
    } else {
      ShowProfileCompleteConfirmation();
    }
  }

  const OnGettingStartedClicked = (e) => {
    e.preventDefault();
    if (parseInt(userStatus) === 1) {
      onGettingStarted();
    } else {
      ShowProfileCompleteConfirmation();
    }
  }

  const OnResumeStartedClicked = (e) => {
    e.preventDefault();
    if (parseInt(userStatus) === 1) {
      onResumeStarted();
    } else {
      ShowProfileCompleteConfirmation();
    }
  }

  const ShowProfileCompleteConfirmation = async () => {
    document.getElementById("profileComplete_pop").style.display = "block";
  };

  return (
    <>
      <div className="topnav" id={divId}>
        {responsive ? (
          <Link to="#">
            <div className="logoPosResponsive i3LendingLogo"></div>
          </Link>
        ) : (
          <div className="logoPosInline i3LendingLogo"></div>
        )}
        <div className="dropdown">
          <button
            onClick={(e) => OnNavigateClicked(e, "/dashboard")}
            className={IsActive(mnu6)}
          >
            <div className={IsActive(mnu6, "dashboardIcon")}></div>Dashboard
          </button>
        </div>
        <div className="dropdown">
          <button className={IsActive(mnu7)}>
            <div className={IsActive(mnu7, "documentIcon")}></div>Documents{" "}
            <div className="arrow_down_topnav"></div>
          </button>
          <div className="dropdown-content">
            <Link to="#" onClick={(e) => OnNavigateClicked(e, "/docview")}>View Documents</Link>
            <Link to="#" onClick={(e) => OnNavigateClicked(e, "/docupload")}>Upload Documents</Link>
            <Link to="#" onClick={(e) => OnNavigateClicked(e, "/docapprove")}>Approvals</Link>
            <Link to="#" onClick={(e) => OnNavigateClicked(e, "/predocs")}>Pre-Approval Documents</Link>
          </div>
        </div>
        <div className="dropdown">
          <button className={IsActive(mnu8)}>
            <div className={IsActive(mnu8, "preapproveIcon")}></div>Pre-Approval{" "}
            <div className="arrow_down_topnav"></div>
          </button>
          <div className="dropdown-content">
            {parseInt(stage) === 0 ? (
              <Link to="#" onClick={(e) => OnGettingStartedClicked(e)}>
                Get Pre-Approved
              </Link>
            ) : (
              <Link to="#" onClick={(e) => OnResumeStartedClicked(e)}>
                Resume Saved Form
              </Link>
            )}
            <Link to="#" onClick={(e) => OnNavigateClicked(e, "/letter")} >Pre-Approval Letter</Link>
          </div>
        </div>
        <div className="dropdown">
          <button className={IsActive(mnu9)}>
            <div className={IsActive(mnu9, "formIcon")}></div>1003 Form
            <div className="arrow_down_topnav"></div>
          </button>
          <div className="dropdown-content">
            <Link to="#" onClick={(e) => OnAuthorizeApp(e)}>
              Complete Application
            </Link>
            <Link to="#" onClick={(e) => OnNavigateClicked(e, "/short")}>Short Application</Link>
            <Link to="#" onClick={(e) => OnNavigateClicked(e, "/saved")}>Saved & Completed Applications</Link>
          </div>
        </div>
        <Link
          to="#"
          className="icon"
          onClick={() => window.myFunction()}
        ></Link>
      </div>
    </>
  );
};

const LoggedInAccountHeader = (props) => {
  const { responsive } = props;
  const [fullName, setFullName] = React.useState("Hello, Sign In");
  const NavigateTo = useNavigate();

  React.useEffect(() => {
    const fn = async () => {
      let user = await Session.RetrieveJSONAsync("user");
      setFullName(user.name);
    };
    fn();
  }, []);

  const OnSignOutClicked = async (e) => {
    e.preventDefault();
    await Session.RemoveAsync("user");
    await Session.RemoveAsync("userToken");
    await Session.RemoveAsync("status");
    NavigateTo("/");
  };

  const OnChangePasswordClicked = (e) => {
    e.preventDefault();
    document.getElementById("changepswd_pop").style.display = "block";
  }

  return (
    <>
      <div className="headerTopRightResponsive">
        <ul className="header_Account_blk_responsive">
          <li className="header_profile_img_responsive"></li>
          <li className="header_nameAcc_blk_responsive">
            <div className="headerProfileName_mini" title={fullName}>
              Hello, {fullName}
            </div>
            <div className="headerAccount_mini">
              <div className="accountnav_mini" id="myAccountnav">
                <div className="dropdownAcc_mini">
                  <button className="dropbtnAcc_mini">
                    Account <div className="arrow_down_mini"></div>
                  </button>
                  <div className="dropdown-content_mini">
                    {/* <Link to="#" className="icon"><div className="loginIcon"></div>Login / Signup</Link> */}
                    {/* <Link to="#"><div className="profileInfoIcon"></div>Profile Information</Link> */}
                    <Link to="/profile">
                      <div className="profileInfoIcon"></div>Profile
                    </Link>
                    <Link to="/support">
                      <div className="supportIcon"></div>Help &amp; Support
                    </Link>
                    <Link to="/uconsent">
                      <div className="consentIcon"></div>Consents
                    </Link>
                    <Link to="/subscription">
                      <div className="subscriptionIcon"></div>Subscriptions
                    </Link>
                    <Link to="#" onClick={(e) => OnChangePasswordClicked(e)}>
                      <div className="fgtpasswordIcon"></div>Change Password
                    </Link>
                    <Link to="#" onClick={(e) => OnSignOutClicked(e)}>
                      <div className="logoutNewIcon"></div>Logout
                    </Link>
                  </div>
                </div>
                <Link
                  to="#"
                  className="icon"
                  onClick={() => window.accFunction()}
                ></Link>
              </div>
            </div>
          </li>
        </ul>
        {!responsive && (
          <div className="header_Ph_blk_responsive">
            <div className="callus">Call us at</div>
            <div className="phNo">(888) 975-1374</div>
          </div>
        )}
      </div>
    </>
  );
};

const LoggedInHeader = () => {
  const [stage, setStage] = React.useState(0);
  const [confirmShow, setConfirmShow] = React.useState(false);
  const NavigateTo = useNavigate();
  const [preApproveStatus] = UseTimerStore('preapprove');

  React.useEffect(() => {
    let nav = preApproveStatus || 0;
    setStage(nav);
  }, [preApproveStatus]);

  const OnGettingStarted = async (e) => {
    let data = await Storage.RetrieveJSONAsync("stages");
    let nav = data["stage"];
    if (!nav) {
      document.getElementById("ziplook_pop").style.display = "block";
    } else {
      NavigateTo("/preapprove");
    }
  };

  const OnResumeStarted = async (e) => {
    setConfirmShow(true);
  };

  const OnCancelClicked = (e) => {
    setConfirmShow(false);
  };

  const OnConfirmClicked = async (e) => {
    if (!e) {
      await Storage.RemoveAsync("stages");
      await Storage.RemoveAsync("preapprove");
      setStage(0);
    }
    setConfirmShow(false);
    OnGettingStarted(e);
  };

  return (
    <>
      <header className="headerInpage">
        <div className="widthfix">
          <div className="topRight_fixed_blk">
            <div className="widthfix">
              <LoggedInAccountHeader responsive={false} />
            </div>
          </div>
        </div>
        <div className="navBlockInpage">
          <div className="widthfix">
            <LoggedInHeaderMenuItems
              responsive={false}
              stage={stage}
              onGettingStarted={OnGettingStarted}
              onResumeStarted={OnResumeStarted}
            />
          </div>
        </div>
      </header>

      <header className="headerResponsive">
        <LoggedInAccountHeader responsive={true} />
        <div className="navBlockResponsive">
          <LoggedInHeaderMenuItems
            responsive={true}
            stage={stage}
            onGettingStarted={OnGettingStarted}
            onResumeStarted={OnResumeStarted}
          />
        </div>
      </header>

      <ConfirmDialog
        title="Do you want to continue with your Pre-approval?"
        message="You'll be able to pick from where you left"
        display={confirmShow}
        onConfirmClicked={OnConfirmClicked}
        onCancelClicked={OnCancelClicked}
      />
    </>
  );
};

const Component = () => {
  const [userToken] = useTimerSession("userToken");
  return (
    <>
      {userToken ? <LoggedInHeader /> : <LoggedOutHeader />}
      <Authentication />
      <ZipPopup />
      <Authorization />
      <ChangePassword />
      <ProfileComplete />
    </>
  );
};

export default Component;
