import React from "react";
import helper from "../helper";

const Component = (props) => {

    const { index, path, name, value, valuetype, maxLength, onBlurError, onInputClicked, onInputChildChanged,
        required, validate, requiredMessage, validationMessage,
        readOnly, currency, placeholder, errors, dataRequired,
        inputtype } = props;

    const [dataError, setDataError] = React.useState(false);

    let tInputType = inputtype || "text";

    const OnInputClicked = (e) => {
        e.preventDefault();
        if (onInputClicked) {
            onInputClicked(e.target.name);
        }
    }

    const OnBlurChanged = async (e) => {
        e.preventDefault();

        const name = e.target.name;
        const value = e.target.value;
        setDataError(false);

        if (valuetype && valuetype === "EMAIL") {
            if (required && helper.IsNullValue(value)) {
                RaiseOnBlurError(name, requiredMessage); return;
            } else if (validate && !helper.IsNullValue(value) && !helper.IsEmailValid(value)) {
                RaiseOnBlurError(name, validationMessage); return;
            } else {
                RaiseOnBlurError(name, null, true);
            }
        } else if (valuetype && valuetype === "PHONE") {
            let formatVal = helper.ToUSAPhone(value);
            if (required && helper.IsNullValue(value)) {
                RaiseOnBlurError(name, requiredMessage); return;
            } else if (validate && !helper.IsNullValue(value) && helper.IsNullValue(formatVal)) {
                RaiseOnBlurError(name, validationMessage); return;
            } else {
                OnTextInputChanged(e, formatVal);
                RaiseOnBlurError(name, null, true);
            }
        } else if (valuetype && valuetype === "SSN") {
            let formatVal = helper.ToSSNNumber(value);
            if (required && helper.IsNullValue(value)) {
                RaiseOnBlurError(name, requiredMessage); return;
            } else if (validate && !helper.IsNullValue(value) && helper.IsNullValue(formatVal)) {
                RaiseOnBlurError(name, validationMessage); return;
            } else {
                OnTextInputChanged(e, formatVal);
                RaiseOnBlurError(name, null, true);
            }
        } else if (valuetype && valuetype === "DATE") {
            if (required && helper.IsNullValue(value)) {
                RaiseOnBlurError(name, requiredMessage); return;
            } else if (validate && !helper.IsNullValue(value) && !helper.IsValidateDate(value)) {
                RaiseOnBlurError(name, validationMessage); return;
            } else {
                RaiseOnBlurError(name, null, true);
            }
        } else if (valuetype && valuetype === "DOB") {
            if (required && helper.IsNullValue(value)) {
                RaiseOnBlurError(name, requiredMessage); return;
            } else if (validate && !helper.IsNullValue(value) && !helper.IsValidateDate(value, "DOB")) {
                RaiseOnBlurError(name, validationMessage); return;
            } else {
                RaiseOnBlurError(name, null, true);
            }
        } else if (valuetype && valuetype === "ZIP") {
            if (required && helper.IsNullValue(value)) {
                RaiseOnBlurError(name, requiredMessage); return;
            } else if (validate && !helper.IsNullValue(value)) {
                global.Busy(true);
                await helper.IsValidZipCode(value)
                    .then((res) => {
                        let tmp = res.data;

                        let data = {
                            country: tmp['country'],
                            countryAbbr: tmp['country abbreviation'],
                            state: tmp.places[0]['state'],
                            stateAbbr: tmp.places[0]['state abbreviation'],
                            city: tmp.places[0]['place name']
                        }

                        RaiseOnBlurError(name, null, true, data);
                    })
                    .catch(() => RaiseOnBlurError(name, validationMessage));
                return;
            } else {
                RaiseOnBlurError(name, null, true);
            }
        } else {
            if (required && helper.IsNullValue(value)) {
                RaiseOnBlurError(name, requiredMessage); return;
            } else {
                RaiseOnBlurError(name, null, true);
            }
        }
    };

    const RaiseOnBlurError = (name, val, clear, data) => {
        global.Busy(false);
        if (onBlurError) {
            if (!clear) {
                setDataError(true);
            } else {
                setDataError(false);
            }
            onBlurError(name, val, clear, data);
        }
    }

    const OnTextInputChanged = (e, formatVal) => {
        e.preventDefault();
        if (onInputChildChanged) {
            const value = formatVal ? formatVal : e.target.value;
            let _name = GetKeyName();
            onInputChildChanged(_name, value, index);
        }
    };

    const GetKeyName = () => {
        let _name = `textinput`;
        if (name) {
            _name = `${name.replace(/\./g, '_')}`;
        } if (path) {
            _name = `${path.replace(/\./g, '_')}`;
        }
        return _name;
    };

    if (helper.IsNullValue(currency)) {
        return (
            <input
                autoComplete={"off"}
                id={GetKeyName()}
                name={GetKeyName()}
                type={tInputType}
                value={helper.ToString(value)}
                maxLength={maxLength && maxLength}
                placeholder={helper.ToString(placeholder)}
                onChange={(e) => OnTextInputChanged(e)}
                style={{
                    borderColor: helper.GetErrorBackColor(errors, GetKeyName()),
                }}
                onKeyPress={(e) => {
                    return valuetype && helper.AcceptedChars(e, valuetype);
                }}
                onBlur={(e) => OnBlurChanged(e)}
                onFocus={(e) => OnInputClicked(e)}
                readOnly={readOnly ? true : undefined}
                data-req={dataRequired}
                data-error={dataError}
                data-req-msg={requiredMessage}
                data-val-msg={validationMessage}
            />
        );
    } else {
        return (
            <div className="input-icon">
                <input
                    autoComplete={"off"}
                    id={GetKeyName()}
                    name={GetKeyName()}
                    type="text"
                    value={helper.ToString(value)}
                    maxLength={maxLength && maxLength}
                    placeholder={helper.ToString(placeholder)}
                    onChange={(e) => OnTextInputChanged(e)}
                    style={{
                        borderColor: helper.GetErrorBackColor(errors, GetKeyName()),
                        paddingLeft: 20,
                    }}
                    onKeyPress={(e) => {
                        return valuetype && helper.AcceptedChars(e, valuetype);
                    }}
                    onBlur={(e) => OnBlurChanged(e)}
                    readOnly={readOnly ? true : undefined}
                    data-req={dataRequired}
                    data-req-msg={requiredMessage}
                    data-val-msg={validationMessage}
                />
                <i>{currency}</i>
            </div>
        );
    }
};

export default Component;
