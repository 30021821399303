import React from "react";
import helper from "../helper";

const Component = (props) => {
  const {
    tag, name, value, type, maxLength, readOnly, currency,
    onInputChildChanged, placeholder, errors, index, parent,
    path, inputtype, onBlurError, onInputClicked, required, tagAsError
  } = props;

  let tIndex = index || 0;
  let tInputType = inputtype || "text";

  const OnInputClicked = (e, name) => {
    e.preventDefault();
    if (onInputClicked) {
      onInputClicked(name);
    }
  }

  const OnBlurChanged = async (val, name) => {
    if (type && type === "PHONE") {
      let formatVal = helper.ToUSAPhone(val);
      if (val && !formatVal && required && onBlurError) {
        onBlurError(name, "Enter valid phone number");
      } else {
        OnTextInputChanged(formatVal);
        onBlurError(name, null, true);
      }
    } else if (type && type === "SSN") {
      let formatVal = helper.ToSSNNumber(val);
      if (val && !formatVal && required && onBlurError) {
        onBlurError(name, "Enter valid SSN");
      } else {
        OnTextInputChanged(formatVal);
        onBlurError(name, null, true);
      }
    } else if (type && type === "EMAIL") {
      if (!helper.IsEmailValid(val)) {
        onBlurError(name, "Enter valid email");
      } else {
        onBlurError(name, null, true);
      }
    } else if (type && type === "DATE") {
      if (!helper.IsValidateDate(val, "DOB")) {
        if (val && required && onBlurError) {
          onBlurError(name, "Enter valid date of birth");
        }
      } else {
        onBlurError(name, null, true);
      }
    } else if (type && type === "ZIP") {
      await helper.IsValidZipCode(val)
        .then((data) => onBlurError && onBlurError(name, null, true, data))
        .catch(() => onBlurError && onBlurError(name, "Enter valid zip code"));
    }
  };


  const OnTextInputChanged = (e) => {
    if (onInputChildChanged) {
      if (name) {
        onInputChildChanged(parent, path, name, e, tIndex);
      } else {
        onInputChildChanged(parent, path, e, tIndex);
      }
    }
  };

  const GetKeyName = () => {
    let _name = tag || "textinput";
    if (name) {
      return `${_name}_${name.replace(".", "_")}_${tIndex}`;
    } else {
      return `${_name}_${tIndex}`;
    }
  };

  const GetErrorField = () => {
    return tagAsError ? GetKeyName() : name;
  }

  if (helper.IsNullValue(currency)) {
    return (
      <input
        autoComplete={"off"}
        id={GetKeyName()}
        name={GetKeyName()}
        type={tInputType}
        value={helper.ToString(value)}
        maxLength={maxLength && maxLength}
        placeholder={helper.ToString(placeholder)}
        onChange={(e) => OnTextInputChanged(e.target.value)}
        style={{
          borderColor: helper.GetErrorBackColor(errors, GetErrorField()),
        }}
        onKeyPress={(e) => {
          return type && helper.AcceptedChars(e, type);
        }}
        onBlur={(e) => OnBlurChanged(e.target.value, name)}
        onFocus={(e) => OnInputClicked(e, name)}
        readOnly={readOnly ? true : undefined}
      />
    );
  } else {
    return (
      <div className="input-icon">
        <input
          id={GetKeyName()}
          name={GetKeyName()}
          type="text"
          value={helper.ToString(value)}
          maxLength={maxLength && maxLength}
          placeholder={helper.ToString(placeholder)}
          onChange={(e) => OnTextInputChanged(e.target.value)}
          style={{
            borderColor: helper.GetErrorBackColor(errors, GetErrorField()),
            paddingLeft: 20,
          }}
          onKeyPress={(e) => {
            return type && helper.AcceptedChars(e, type);
          }}
          onBlur={(e) => OnBlurChanged(e.target.value, name)}
          readOnly={readOnly ? true : undefined}
        />
        <i>{currency}</i>
      </div>
    );
  }
};

export default Component;
