import API from "./api";
import session from "../session";

const GetUserSubs = async () => {
    let token = await session.GetHeader();
    try {
        const res = await API.get(`subs/user`, { headers: token, });
        return res.data;
    } catch (err) {
        return { statusText: err.message, status: 900 };
    }
};

const SetUserSubs = async (input) => {
    let token = await session.GetHeader();
    try {
        const res = await API.post(`subs/user`, input, { headers: token, });
        return res.data;
    } catch (err) {
        return { statusText: err.message, status: 900 };
    }
};


export { GetUserSubs, SetUserSubs };
