import API from "./api";

const SignIn = async (input) => {
    try {
        const res = await API.post("authenticate", input);
        return res.data;
    } catch (err) {
        return { statusText: err.message, status: 900 };
    }
};

const SignUp = async (input) => {
    try {
        const res = await API.post("register/user", input);
        return res.data;
    } catch (err) {
        return { statusText: err.message, status: 900 };
    }
};

const CheckAndSignUp = async (input) => {
    try {
        const res = await API.post("register/check", input);
        return res.data;
    } catch (err) {
        return { statusText: err.message, status: 900 };
    }
};

export { SignIn, SignUp, CheckAndSignUp };
