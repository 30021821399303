import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import Home from "../screens/home";
import Dashboard from "../screens/dashboard";
import Profile from "../screens/profiles";
import Support from "../screens/support";
import UserConsent from "../screens/consents";
import Subscription from "../screens/subscription";

import Session from "../session";

// Form1003 Stages
import {
  AppShort, Thanks, PrintPreview, SavedList, CompletedList,
  AppStage1, AppStage2, AppStage3, AppStage4, AppStage5, AppStage6
} from "../screens/form1003/stages";

// Generic Page Links
import {
  Statistics, About, Career, Blog, Review, Contact,
  BecomeBranch, FindLoanOfficer, Terms, Privacy, Disclosure,
  Branch, Quote, Consent
} from "../screens/home/generic";

//Buy Home Links
import {
  BuyFirstHome,
  BuyNextHome,
  BuildNewHome,
  ResidentialProperty,
  ForeclosedHome,
} from "../screens/home/buy";

//Refinance Links
import {
  LowerPayment,
  ReduceRate,
  PayOff,
  Convert,
  Consolidate,
  PrepareRefinance,
  RefinanceSteps,
} from "../screens/home/refinance";

//Mortgage Tools
import { LoanProcess, MortgageRates, FAQ } from "../screens/home/mortgagetools";

//Loan Options Links
import {
  ThirtyYr,
  FHA,
  VA,
  Reverse,
  USDA,
  Jumbo,
  Renovation,
  NonQM,
  Conventional,
} from "../screens/home/loanoptions";

//Calculators
import {
  MortgageCalculator, RefinanceCalculator, PrincipalCalculator,
  ExtraPayment, IncomeToQualify, Affordability, TaxBenefits, APR
} from "../screens/home/mortgagetools/calculators";

// Document Links
import {
  ApproveDocuments,
  UploadDocuments,
  ViewDocuments,
  PreApprovalDocuments
} from "../screens/documents";


import { Blog1, Blog2, Blog3 } from "../screens/home/generic/blog/blogs";

// Pre-Approvals
import ApprovedLetter from "../screens/preapproval/letter";
import PreApproved from "../screens/preapproval";

const RouteLinks = [
  { id: 1, path: "", Component: Home },
  { id: 2, path: "firsthome", Component: BuyFirstHome },
  { id: 3, path: "nexthome", Component: BuyNextHome },
  { id: 4, path: "newhome", Component: BuildNewHome },
  { id: 5, path: "residential", Component: ResidentialProperty },
  { id: 6, path: "foreclosed", Component: ForeclosedHome },
  { id: 7, path: "lowerpayment", Component: LowerPayment },
  { id: 8, path: "reducerate", Component: ReduceRate },
  { id: 9, path: "payoff", Component: PayOff },
  { id: 10, path: "convert", Component: Convert },
  { id: 11, path: "consolidate", Component: Consolidate },
  { id: 12, path: "prepare", Component: PrepareRefinance },
  { id: 13, path: "steps", Component: RefinanceSteps },
  { id: 14, path: "loanprocess", Component: LoanProcess },
  { id: 15, path: "mortgagerates", Component: MortgageRates },
  { id: 16, path: "mortgagecalculator", Component: MortgageCalculator },
  { id: 17, path: "refinancecalculator", Component: RefinanceCalculator },
  { id: 18, path: "principalcalculator", Component: PrincipalCalculator },
  { id: 19, path: "faq", Component: FAQ },
  { id: 20, path: "thirty", Component: ThirtyYr },
  { id: 21, path: "fha", Component: FHA },
  { id: 22, path: "va", Component: VA },
  { id: 23, path: "reverse", Component: Reverse },
  { id: 24, path: "usda", Component: USDA },
  { id: 25, path: "jumbo", Component: Jumbo },
  { id: 26, path: "renovation", Component: Renovation },
  { id: 27, path: "nonqm", Component: NonQM },
  { id: 28, path: "conventional", Component: Conventional },
  { id: 29, path: "stats", Component: Statistics },
  { id: 30, path: "about", Component: About },
  { id: 31, path: "career", Component: Career },
  { id: 32, path: "blogs", Component: Blog },
  { id: 33, path: "reviews", Component: Review },
  { id: 34, path: "contact", Component: Contact },
  { id: 35, path: "becomebranch", Component: BecomeBranch },
  { id: 36, path: "findlo", Component: FindLoanOfficer },
  { id: 37, path: "terms", Component: Terms },
  { id: 38, path: "privacy", Component: Privacy },
  { id: 39, path: "disclosure", Component: Disclosure },
  { id: 40, path: "branch", Component: Branch },
  { id: 41, path: "quote", Component: Quote },
  { id: 42, path: "stage1", Component: AppStage1, Session: true },
  { id: 43, path: "stage2", Component: AppStage2, Session: true },
  { id: 44, path: "stage3", Component: AppStage3, Session: true },
  { id: 45, path: "stage4", Component: AppStage4, Session: true },
  { id: 46, path: "stage5", Component: AppStage5, Session: true },
  { id: 47, path: "stage6", Component: AppStage6, Session: true },
  { id: 48, path: "short", Component: AppShort, Session: true },
  { id: 49, path: "thank", Component: Thanks, Session: true },
  { id: 50, path: "preview", Component: PrintPreview, Session: true },
  { id: 51, path: "dashboard", Component: Dashboard, Session: true },
  { id: 52, path: "docapprove", Component: ApproveDocuments, Session: true },
  { id: 53, path: "docview", Component: ViewDocuments, Session: true },
  { id: 54, path: "docupload", Component: UploadDocuments, Session: true },
  { id: 54, path: "predocs", Component: PreApprovalDocuments, Session: true },
  { id: 55, path: "letter", Component: ApprovedLetter, Session: true },
  { id: 56, path: "preapprove", Component: PreApproved },
  { id: 57, path: "saved", Component: SavedList, Session: true },
  { id: 58, path: "download", Component: CompletedList, Session: true },
  { id: 59, path: "profile", Component: Profile, Session: true },
  { id: 60, path: "support", Component: Support },
  { id: 61, path: "extrapayment", Component: ExtraPayment },
  { id: 62, path: "incometoqualify", Component: IncomeToQualify },
  { id: 73, path: "affordability", Component: Affordability },
  { id: 74, path: "taxbenefit", Component: TaxBenefits },
  { id: 75, path: "apr", Component: APR },
  { id: 76, path: "blog1", Component: Blog1 },
  { id: 77, path: "blog2", Component: Blog2 },
  { id: 78, path: "blog3", Component: Blog3 },
  { id: 79, path: "consent", Component: Consent },
  { id: 80, path: "uconsent", Component: UserConsent },
  { id: 81, path: "subscription", Component: Subscription },
];

function PrivateRoute({ children }) {
  let loggedin = Session.IsLoggedIn();
  return loggedin ? children : <Navigate to="/" />;
}

const RendorRoutes = () => {
  return (
    <Routes>
      <Route
        path={"/docapprove/:id"}
        exact
        element={
          <PrivateRoute>
            <ApproveDocuments />
          </PrivateRoute>
        }
      />

      <Route
        path={"/docupload/:id"}
        exact
        element={
          <PrivateRoute>
            <UploadDocuments />
          </PrivateRoute>
        }
      />

      <Route
        path={"/docview/:id"}
        exact
        element={
          <PrivateRoute>
            <ViewDocuments />
          </PrivateRoute>
        }
      />

      <Route
        path={"/predocs/:id"}
        exact
        element={
          <PrivateRoute>
            <PreApprovalDocuments />
          </PrivateRoute>
        }
      />
      <Route
        path={"/uconsent/:id"}
        exact
        element={
          <PrivateRoute>
            <UserConsent />
          </PrivateRoute>
        }
      />

      {RouteLinks.map((x) => {
        if (x.Session) {
          return (
            <Route
              key={x.id}
              path={"/" + x.path}
              exact
              element={
                <PrivateRoute>
                  <x.Component />
                </PrivateRoute>
              }
            />
          );
        } else {
          return (
            <Route
              key={x.id}
              path={"/" + x.path}
              exact
              element={<x.Component />}
            />
          );
        }
      })}
    </Routes>
  );
};

export default RendorRoutes;
