import API from "./api";
import session from "../session";

const GetUserRequestList = async () => {
    let token = await session.GetHeader();
    try {
        const res = await API.get(`consents/request/user`, { headers: token, });
        return res.data;
    } catch (err) {
        return { statusText: err.message, status: 900 };
    }
};

const GetUserRequest = async (id) => {
    let token = await session.GetHeader();
    try {
        const res = await API.get(`consents/request/user/${id}`, { headers: token, });
        return res.data;
    } catch (err) {
        return { statusText: err.message, status: 900 };
    }
};

const SetUserRequestStatus = async (id, status) => {
    let token = await session.GetHeader();
    try {
        const res = await API.get(`consents/request/user/${id}/${status}`, { headers: token, });
        return res.data;
    } catch (err) {
        return { statusText: err.message, status: 900 };
    }
};

export { GetUserRequest, GetUserRequestList, SetUserRequestStatus };
