import {
  React, Storage, Helper, Support,
  RadioInput2 as RadioInput, DropDown2 as DropDown, TextInput2 as TextInput
} from "../../../common";
import RenderBreadCrumb from "./childs/RenderBreadCrumb";
import { GetAllLoanOffiers } from "../../../services/loanApi";

const Component = ({ onClick }) => {
  const [row, setRow] = React.useState({});
  const [error, setError] = React.useState(null);
  const [errors, setErrors] = React.useState([]);
  const [stage, setStage] = React.useState(0);
  const [state, setState] = React.useState(false);
  const [initialized, setInitialized] = React.useState(false);
  const [loanOfficers, setLoanOfficers] = React.useState([]);

  React.useEffect(() => {
    setInitialized(true);
  }, []);

  React.useEffect(() => {
    if (!Helper.IsNullValue(row?.loanOfficerId)) ExtractOfficerDetails(row?.loanOfficerId);
  }, [row?.loanOfficerId]);

  const FetchResults = async () => {
    global.Busy(true);
    let rslt = await GetAllLoanOffiers();
    if (rslt.status === 100) {
      let tmp = rslt.data;
      tmp = Helper.RemoveDuplicates(tmp, "fullname");
      tmp.splice(0, 0, { id: 0, value: "", fullname: "Select" });
      setLoanOfficers(tmp);
    }
    global.Busy(false);
  };

  if (initialized) {
    setInitialized(false);
    setErrors([]);
    let data = Storage.RetrieveJSON("stages");
    setRow(data.stage2 || {});
    setStage(2);
    FetchResults();
  }

  const onInputChanged = async (path, value, index) => {
    let newRow = Helper.ModifyJsonArrayObject4(row, path, value);
    GetIndicatorText(newRow, path, value);
    setRow(newRow);
    setState(!state);
  }

  const ExtractOfficerDetails = (id) => {
    let tmp = loanOfficers.filter((x) => parseInt(x.id) === parseInt(id));
    let _data = {};
    if (tmp && tmp.length > 0) _data = tmp[0];
    row.loanOfficerEmail = _data.email;
    row.loanOfficerPhone = _data.work_phone;
    row.loanOfficerNMLSId = _data.nmls_id;
    row.loanOfficerLicenseState = _data.statename;
    row.loanOfficerLicenseID = _data.license;
    row.loanOfficer = _data.fullname;
    setState(!state);
  };

  const GetIndicatorText = async (nRow, name, value) => {
    let titlename = undefined;
    let title = undefined;
    switch (name) {
      case "propertyMortgageApplied":
        titlename = "propertyMortgageAppliedText";
        title = Support.MortgageTypes().filter(
          (x) => parseInt(x.value) === parseInt(value)
        )[0].text;
        break;
      case "propertyPriceIndicator":
        titlename = "propertyPriceIndicatorText";
        title = Support.PropertyPrices().filter(
          (x) => parseInt(x.value) === parseInt(value)
        )[0].label;
        break;
      case "propertyUseIndicator":
        titlename = "propertyUseIndicatorText";
        title = Support.PropertyUses().filter(
          (x) => parseInt(x.value) === parseInt(value)
        )[0].label;
        break;
      case "propertyIndicator":
        titlename = "propertyIndicatorText";
        title = Support.PropertyTypes().filter(
          (x) => parseInt(x.value) === parseInt(value)
        )[0].label;
        break;
      default:
        titlename = undefined;
        title = undefined;
        break;
    }
    if (titlename) {
      nRow[titlename] = title;
    }
  };

  const OnNavClicked = async (type, e) => {

    if (Helper.IsNullValue(row?.loanOfficerIndicator)) delete row['loanOfficerIndicator'];
    if (Helper.IsNullValue(row?.loanOfficerId) || Helper.ToInteger(row?.loanOfficerId) < 1) delete row['loanOfficerId'];

    setError(null);
    setErrors([]);

    if (type === "prev") {
      if (onClick) onClick(e);
      return;
    }

    let _fields = [
      "propertyAddressZip",
      "propertyAddressCity",
      "propertyAddressState",
      "propertyAddressText",
      "propertyIndicator",
      "propertyUseIndicator",
      "propertyPriceIndicator",
      "propertyDownPayment",
      "propertyMortgageApplied"
    ];

    let _errs = [];
    _fields.forEach((elm) => {
      _errs.push({ field: elm });
    });

    if (Helper.IsJSONEmpty(row)) {
      setErrors(_errs);
      setError("All fields are mandatory");
      return;
    }

    _errs = [];
    _fields.forEach((elm) => {
      if (Helper.IsNullValue(row[elm])) {
        _errs.push({ field: elm });
      }
    });

    if (Helper.IsNullValue(row?.loanOfficerIndicator)) {
      _errs.push({ field: 'loanOfficerIndicator' });
    } else if (row?.loanOfficerIndicator && Helper.IsNullValue(row?.loanOfficerId)) {
      _errs.push({ field: 'loanOfficerId' });
    }

    if (_errs && _errs.length > 0) {
      setErrors(_errs);
      setError("All fields are mandatory");
      return;
    }

    let data = Storage.RetrieveJSON("stages");
    data["stage2"] = row;
    data["stage"] = e;

    Storage.StoreJSON("stages", data);
    if (onClick) onClick(e);
  };

  const onBlurError = (name, val, clear, data) => {
    let _errors = [];
    if (errors && errors.length > 0) _errors = errors.filter((x) => x.field !== name);
    if (Helper.IsNullValue(clear)) _errors.push({ field: name, value: val });
    setErrors(_errors);
    setError(val);
    if (name === 'propertyAddressZip' && !Helper.IsJSONEmpty(data)) {
      row['propertyAddressCity'] = data['city'];
      row['propertyAddressState'] = data['stateAbbr'];
      row['propertyAddressCountry'] = data['countryAbbr'];
      setState(!state);
    }
  };

  return (
    <>
      <RenderBreadCrumb stage={2} row={row} onClick={onClick} />
      <li className="preApprove_IIcol_285by100pc">
        <ul className="step_count">
          <li className="stepHead">Tell us a bit more about your property</li>
          <li className="countBlk">
            <div className="countNo">2/5</div>
          </li>
        </ul>
        <div className="purchase">
          <div className="spacer_25margin_bottom">
            <div className="pur_Q">
              What is the property address you are purchasing? (Please add the state and zip)
            </div>
            <ul className="contentIIIcol">
              <li className="contentIIIcol">
                <TextInput
                  placeholder={"Postal Code"}
                  errors={errors}
                  path={"propertyAddressZip"}
                  value={Helper.ToString(row?.propertyAddressZip)}
                  onInputChildChanged={onInputChanged}
                  onBlurError={onBlurError}
                  valuetype={"ZIP"}
                  validate={true}
                />
              </li>
              <li className="contentIIIcol">
                <TextInput
                  placeholder={"City"}
                  errors={errors}
                  path={"propertyAddressCity"}
                  value={Helper.ToString(row?.propertyAddressCity)}
                  onInputChildChanged={onInputChanged}
                  onBlurError={onBlurError}
                />
              </li>
              <li className="contentIIIcol">
                <TextInput
                  placeholder={"State"}
                  errors={errors}
                  path={"propertyAddressState"}
                  value={Helper.ToString(row?.propertyAddressState)}
                  onInputChildChanged={onInputChanged}
                  onBlurError={onBlurError}
                />
              </li>
            </ul>
            <TextInput
              placeholder={"if you have not decided on the property just type - TBD"}
              errors={errors}
              path={"propertyAddressText"}
              value={Helper.ToString(row?.propertyAddressText)}
              onInputChildChanged={onInputChanged}
              onBlurError={onBlurError}
            />
          </div>
          <div className="spacer_10margin_bottom">
            <ul className="contentIIcol">
              <li className="contentIIcol">
                <div className="pur_Q">Are you working with a Loan Officer?</div>
                <DropDown
                  name={"loanOfficerIndicator"}
                  value={Helper.ToString(row?.loanOfficerIndicator)}
                  items={Support.LoanOfficerTypes()}
                  displayName={"label"}
                  displayValue={"value"}
                  onInputChildChanged={onInputChanged}
                  errors={errors}
                  valuetype={'BOOL'}
                />
              </li>
              <li className="contentIIcol">
                <div className="pur_Q">Select the Loan Officer</div>
                <DropDown
                  name={"loanOfficerId"}
                  value={row?.loanOfficerId}
                  items={loanOfficers}
                  displayName={"fullname"}
                  displayValue={"id"}
                  onInputChildChanged={onInputChanged}
                  disabled={
                    !Helper.ToUndefinedCheck(row?.loanOfficerIndicator)
                  }
                  errors={errors}
                />
              </li>
            </ul>
          </div>
          <div className="spacer_15margin_bottom">
            <div className="pur_Q">What property type would this be?</div>
            <ul className="pur_A">

              <RadioInput
                errors={errors}
                name={"propertyIndicator"}
                value={Helper.ToInteger(row?.propertyIndicator)}
                options={Support.PropertyTypes()}
                uselabel={false}
                onInputChildChanged={onInputChanged}
              />

            </ul>
          </div>
          <div className="spacer_15margin_bottom">
            <div className="pur_Q">What is the property use?</div>
            <ul className="pur_A">
              <RadioInput
                errors={errors}
                name={"propertyUseIndicator"}
                value={Helper.ToInteger(row?.propertyUseIndicator)}
                options={Support.PropertyUses()}
                uselabel={false}
                onInputChildChanged={onInputChanged}
              />
            </ul>
          </div>
          <div className="spacer_15margin_bottom">
            <div className="pur_Q">Estimated purchase price</div>
            <ul className="pur_A">
              <RadioInput
                errors={errors}
                name={"propertyPriceIndicator"}
                options={Support.PropertyPrices()}
                value={Helper.ToInteger(row?.propertyPriceIndicator)}
                uselabel={false}
                onInputChildChanged={onInputChanged}
              />
            </ul>
          </div>
          <div className="spacer_15margin_bottom">
            <ul className="III_col">
              <li className="III_col">
                <div className="pur_Q">Down payment</div>
                <TextInput
                  currency="$"
                  maxLength={14}
                  name={"propertyDownPayment"}
                  placeholder={"DownPayment"}
                  value={Helper.ToString(row?.propertyDownPayment)}
                  type={"NFINANCE"}
                  onInputChildChanged={onInputChanged}
                  errors={errors}
                />

              </li>
              <li className="III_col">
                <div className="pur_Q">Mortgage Applied For</div>
                <DropDown
                  name={"propertyMortgageApplied"}
                  value={Helper.ToString(row?.propertyMortgageApplied)}
                  items={Support.MortgageTypes()}
                  displayName={"text"}
                  displayValue={"value"}
                  onInputChildChanged={onInputChanged}
                  errors={errors}
                />
              </li>
            </ul>
          </div>
          <div className="button_preApprove_blk">
            <input
              type="button"
              defaultValue="Previous"
              className="button_steps"
              onClick={(e) => OnNavClicked("prev", stage - 1)}
            />
            <input
              type="button"
              defaultValue="Next"
              className="button_steps"
              onClick={(e) => OnNavClicked("next", stage + 1)}
            />
          </div>
          {error && (
            <div className="errormessage">{error}</div>
          )}
        </div>
      </li>
    </>
  );
};

export default Component;
