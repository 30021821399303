import { React, Storage, Helper, Link, Session } from "../../../common";
import RenderBreadCrumb from "./childs/RenderBreadCrumb";
import { SignIn, CheckAndSignUp } from "../../../services/authApi";

const Component = ({ onClick }) => {
  const [initialized, setInitialized] = React.useState(false);
  const [stages, setStages] = React.useState({});
  const [stage, setStage] = React.useState(0);
  const [email, setEmail] = React.useState("");
  const [loginKey, setLoginKey] = React.useState("");
  const [error, setError] = React.useState(null);
  const [received, setReceived] = React.useState(false);
  const [scope, setScope] = React.useState("");

  React.useEffect(() => {
    setInitialized(true);
  }, []);

  const ResetValues = () => {
    setEmail("");
    setLoginKey("");
    setError(null);
    setReceived(false);
  };

  if (initialized) {
    setInitialized(false);
    ResetValues();
    let data = Storage.RetrieveJSON("stages");
    let _email = data?.stage6?.emailAddressText;
    setStages(data);
    setStage(6);
    setEmail(_email);
  }

  const OnOTPLoginAccount = (e) => {
    e.preventDefault();
    let data = {
      email: email,
      scope: "otp",
      apptype: "lend",
    };
    OnVerifyAccount(data);
  };

  const OnEmailLoginAccount = (e) => {
    e.preventDefault();
    let data = {
      email: email,
      scope: "email",
      apptype: "lend",
    };
    OnVerifyAccount(data);
  };

  const OnVerifyAccount = async (e) => {

    let req = {
      ...e,
      firstName: stages.stage3.borrower.firstName,
      lastName: stages.stage3.borrower.lastName,
      mobilePhone: stages.stage3.borrower.mobilePhone,
      redirecturl: Helper.RedirectUrl
    }

    setError(null);
    setReceived(false);
    if (Helper.IsNullValue(req.email) || !Helper.IsEmailValid(req.email)) {
      setError("Enter valid email");
      return;
    }

    global.Busy(true);
    const rslt = await CheckAndSignUp(req);
    global.Busy(false);
    if (rslt.status !== 100) {
      setError(rslt.statusText);
      return;
    }

    setScope(e.scope);
    setReceived(true);
  };

  const OnSignInClicked = async (e, type, num) => {
    e.preventDefault();
    setError(null);
    if (Helper.IsNullValue(email) || !Helper.IsEmailValid(email)) {
      setError("Enter valid email");
      return;
    }

    if (Helper.IsNullValue(loginKey)) {
      if (scope.toUpperCase() === "OTP") {
        setError("Enter Valid OTP");
      } else if (scope.toUpperCase() === "EMAIL") {
        setError("Enter Valid Password");
      }
      return;
    }

    let row = { email: email, scope: scope, apptype: "lend" };

    if (scope.toUpperCase() === "OTP") {
      row.otp = loginKey;
    } else if (scope.toUpperCase() === "EMAIL") {
      row.password = loginKey;
    }

    global.Busy(true);
    let rslt = await SignIn(row);
    global.Busy(false);
    if (rslt.status !== 100) {
      setError(rslt.statusText);
      return;
    }
    rslt = rslt.data;
    await Session.StoreJSON("status", rslt.data.status);
    await Session.StoreJSON("user", rslt.data);
    await Session.Store("userToken", rslt.token);
    OnNavClicked(null, type, num);
  };

  const OnNavClicked = (e, type, num) => {
    if (e) e.preventDefault();
    if (type === "next") {
      let data = Storage.RetrieveJSON("stages");
      data["stage"] = num;
      Storage.StoreJSON("stages", data);
    }
    if (onClick) onClick(num);
  };

  const IsCoBorrowerExist = () => {
    return Helper.ToBool(stages?.stage4?.coBorrowerIndicator);
  };

  return (
    <>
      <RenderBreadCrumb stage={6} onClick={onClick} />
      <li className="preApprove_IIcol_285by100pc">
        <ul className="step_count">
          <li className="stepHead">
            Confirm your account. Then, you'll see your rate.
          </li>
          <li className="countBlk">
            <div className="countNo">{IsCoBorrowerExist() ? "6/6" : "5/5"}</div>
          </li>
        </ul>
        <div className="purchase">
          <div className="spacer_25margin_bottom" style={{ display: "none" }}>
            <div className="su_method">
              <div className="method_head">
                Connect with your social media account
              </div>
              <div className="spacer_10margin_bottom">
                <Link to="#" title="Twitter">
                  <div className="twitterLogo"></div>
                </Link>
                <Link to="#" title="Facebook">
                  <div className="facebookLogo"></div>
                </Link>
                <Link to="#" title="Google">
                  <div className="googleLogo"></div>
                </Link>
              </div>
            </div>
          </div>
          <div className="su_emailmethod">
            Sign In / Sign Up using your email address
          </div>

          <div className="spacer_25margin_bottom">
            <div className="spacer_5margin_bottom">
              <div className="pur_Q">Email Address</div>
            </div>
            <ul className="II_col">
              <li className="II_col">
                <input
                  type="text"
                  placeholder="Email Address"
                  value={Helper.ToString(email)}
                  onChange={(e) => setEmail(e.target.value)}
                  onKeyPress={() => setError(null)}
                />
              </li>
              <li className="II_col">
                <input
                  type="button"
                  value="Login with OTP"
                  className="button_verify"
                  onClick={(e) => OnOTPLoginAccount(e)}
                />
                <input
                  type="button"
                  value="Login with Password"
                  className="button_verify"
                  onClick={(e) => OnEmailLoginAccount(e)}
                />
              </li>
            </ul>
          </div>

          {received && scope.toUpperCase() === "OTP" && (
            <div className="spacer_25margin_bottom">
              <div className="spacer_5margin_bottom">
                <div className="pur_Q">OTP</div>
              </div>
              <ul className="II_col">
                <li className="II_col">
                  <input
                    value={loginKey}
                    placeholder="OTP"
                    type="text"
                    onChange={(e) => setLoginKey(e.target.value)}
                    onKeyPress={(e) => {
                      setError(null);
                      return Helper.AcceptedChars(e);
                    }}
                  />
                </li>
                <li className="II_col">
                  <div className="noteText">
                    Please authenticate using the (OTP) One Time Password that
                    is sent to the email address
                  </div>
                </li>
              </ul>
            </div>
          )}
          {received && scope.toUpperCase() === "EMAIL" && (
            <div className="spacer_25margin_bottom">
              <div className="spacer_5margin_bottom">
                <div className="pur_Q">Password</div>
              </div>
              <ul className="II_col">
                <li className="II_col">
                  <input
                    value={loginKey}
                    placeholder="Password"
                    type="password"
                    onChange={(e) => setLoginKey(e.target.value)}
                  />
                </li>
                <li className="II_col">
                  <div className="noteText">
                    Please authenticate using the Password that is sent to the
                    email address
                  </div>
                </li>
              </ul>
            </div>
          )}
          <div className="haveAcc">
            <div className="signupChkBoxLabel">
              <label
                htmlFor="chkagree">
                By creating an account, you're agreeing to our
                <Link to="/terms">Terms Of Us</Link> and our
                <Link to="/privacy">Security And Privacy Policy</Link>,
                which includes using arbitration to resolve claims related to the Telephone Consumer Protection Act.
              </label>
            </div>
          </div>
          {received && (
            <>
              <div className="button_preApprove_blk">
                <input
                  type="button"
                  defaultValue="Previous"
                  className="button_steps"
                  onClick={(e) => OnNavClicked(e, "prev", stage - 1)}
                />
                <input
                  type="button"
                  defaultValue="Next"
                  className="button_steps"
                  onClick={(e) => OnSignInClicked(e, "next", stage + 1)}
                />
              </div>
            </>
          )}
          {error && (
            <div className="errormessage">{error}</div>
          )}
        </div>
      </li>
    </>
  );
};

export default Component;
